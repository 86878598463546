<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="about_all">
      <img class="imgs" src="../../static/images/my_index/icon_about.png" alt>
      <p class="txt">筑龙学社：http://www.zhulong.com</p>
      <p class="txt1">
        客服热线：
        <a href="#" style="text-decoration:underline;color:#b6244c;">400-900-8066</a>
      </p>
      <p></p>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
// import commontop from '@/compontens/menu.vue'
import Cookies from 'js-cookie'

export default {
  components: {
    heads
    // commontop
  },
  data() {
    return {
      title_msg: '关于我们',
      isshow: false,
      showMore: true,
      zkb_pro: '',
      isZkb: false
    }
  },
  created() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.showMore = false
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    console.log(this.agency_id + this.isZkb + '-----------------')
  },
  methods: {
    clicks() {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.imgs {
  display: block;
  margin: 0 auto;
  width: 134px;
  height: 167px;
  margin-top: 165px;
}
.txt {
  margin-top: 20px;
  text-align: center;
  line-height: 60px;
}
.txt1 {
  text-align: center;
  line-height: 60px;
}
</style>
